import React, { useEffect, useRef, useState } from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'gatsby-link';
import styles from './styles.module.scss';
import { MainContext } from '@helpers/contexts';

const Hero = ({
    $main,
    title,
    description,
    image: Img,
    imageWrapperClassName = '',
    previousCategoryHeading,
    previousCategoryLink,
    nextCategoryHeading,
    nextCategoryLink,
}) => {
    const [offset, setOffset] = useState(0);
    const $hero = useRef(null);

    useEffect(() => {
        $main?.current?.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => $main?.current?.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        const newOffset = $main?.current?.scrollTop / 5;
        setOffset(newOffset >= 0 ? newOffset : 0);
    };

    return (
        <div className={`${styles.hero} bg-white flex flex-col items-center justify-center`}>
            <div className={`max-w-6xl px-5 lg:px-5 flex items-center justify-center h-full mt-12`}>
                <div
                    ref={$hero}
                    style={{ top: offset }}
                    className={`${styles.heroContent} flex flex-col items-center`}
                >
                    <div className={`pb-8 pt-4 flex flex-col items-center justify-center`}>
                        <h1 className="my-1 w-full text-center">{title}</h1>
                        {/*<p className="text-md text-primary my-1 text-center w-full sm:w-2/3 md:w-1/2">*/}
                        {/*    {description}*/}
                            <div
                                className="text-md text-primary my-1 text-center w-full sm:w-2/3 md:w-1/2"
                                dangerouslySetInnerHTML={{ __html: description }} />
                        {/*</p>*/}
                    </div>
                    {!!Img && (
                        <div
                            className={`w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 ${imageWrapperClassName}`}
                        >
                            {typeof Img === 'string' ? (
                                <img alt={`${title} Graphic`} src={Img} />
                            ) : (
                                <Img />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {!!previousCategoryLink && nextCategoryLink && (
                <div className={styles.servicesNavigationWrapper}>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <nav>
                                    <Link to={previousCategoryLink}>
                                        <FontAwesomeIcon icon={['far', 'long-arrow-left']} />
                                        {previousCategoryHeading}
                                    </Link>
                                    <Link to={nextCategoryLink}>
                                        {nextCategoryHeading}
                                        <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
                                    </Link>
                                </nav>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default (props) => (
    <MainContext.Consumer>{($main) => <Hero {...props} $main={$main} />}</MainContext.Consumer>
);
