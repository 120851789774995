import React from 'react';
import styles from './styles.module.scss';
import BlogImg from '@images/svgs/blog.svg';
import Layout from '@components/Layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import Button from '@components/Button';
import { parse, format } from 'date-fns';

const Blog = ({
                  data: {
                      page: {
                          frontmatter: {
                              title,
                              description,
                              graphic: { publicURL: graphic },
                          },
                      },
                      blogs: { edges: blogs },
                  },
              }) => {
    return (
        <Layout>
            <SEO title='Blog' />
            <div>
                <Hero
                    title={title}
                    description={description}
                    image={graphic}
                    imageWrapperClassName='-mt-10'
                />

                <div className='max-w-6xl mx-auto w-full py-24 px-5'>
                    {blogs?.sort((a, b) => {
                        const aDate = parse(a.node.frontmatter.date, 'dd/MM/yyyy', new Date())
                        const bDate = parse(b.node.frontmatter.date, 'dd/MM/yyyy', new Date())
                        return new Date(bDate) - new Date(aDate);
                    })?.map(
                        (
                            {
                                node: {
                                    frontmatter: {
                                        heading,
                                        description,
                                        image,
                                        date,
                                        read_time,
                                        tags,
                                        path,
                                    },
                                },
                            },
                            index,
                        ) => {
                            return (
                                <Link className='w-full' to={path}>
                                    <div
                                        className={`w-full ${
                                            index + 1 === blogs?.length ? '' : 'mb-12'
                                        } flex items-center bg-white  flex-wrap ${styles.blogCard}`}
                                    >
                                        <div className='w-full md:w-2/5 lg:h-full'>
                                            <Img
                                                style={{ height: '100%' }}
                                                fluid={image?.childImageSharp?.fluid}
                                            />
                                        </div>
                                        <div
                                            className={`w-full md:w-3/5 lg:h-full px-10 py-10 ${styles.blogOverviewCard}`}
                                        >
                                            <h4 className='m-0'>{heading}</h4>

                                            <p
                                                className={`${styles.blogOverviewDescription} m-0 mt-6`}
                                            >
                                                {description}
                                            </p>
                                            <p className='mt-4'>
                                                Published {format(parse(date, 'dd/MM/yyyy', new Date()), 'do MMMM yyyy')}.{' '}
                                                <span className='font-bold'>
                                                    {read_time} min read.
                                                </span>
                                            </p>
                                            <div className='mt-4 flex flex-wrap -mb-2'>
                                                {tags?.map(({ name, path }) => {
                                                    return (
                                                        <Link
                                                            className={`mr-2 mb-2 cursor-pointer text-xs ${
                                                                styles.tag
                                                            } ${name.toLowerCase() ===
                                                            'favourite' && `${styles.fav}`}`}
                                                            to={path}
                                                        >
                                                            {' '}
                                                            <span>{name}</span>
                                                        </Link>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        },
                    )}
                </div>
            </div>
            <hr />
            <div className='bg-white'>
                <div className='max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center'>
                    <h2 className='my-2'>Like what we do?</h2>
                    <p className='text-xl mt-2 font-medium  mx-auto max-w-4xl'>
                        Let's make you a beautiful website, chat with us now.
                    </p>
                    <div className='mt-8 flex flex-col lg:flex-row justify-center items-center '>
                        <Link to='tel:01206259355'>
                            <Button text='Phone us' />
                        </Link>
                        <Button
                            secondary
                            onClick={() => window?.FB?.CustomerChat?.show(true)}
                            text='Open chat'
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query BlogQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: blog_title
                description: blog_description
                graphic: blog_graphic {
                    publicURL
                }
            }
        }
        blogs: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blogs/" } }) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: blogs_blog_title
                        image: blogs_blog_overview_image {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        description: blogs_blog_overview_description
                        date: blogs_blog_date
                        read_time: blogs_blog_readtime
                        tags: blogs_blog_tags {
                            name
                            path
                        }
                    }
                }
            }
        }
    }
`;

export default Blog;
